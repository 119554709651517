import { CloseOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import React, { Dispatch } from 'react';

import styles from './styles.module.scss';

type Props = {
  onChange?: Dispatch<any>;
  value?: any;
};

const { RangePicker } = DatePicker;

export const DateFilter: React.FC<Props> = ({ onChange, value }) => (
  <RangePicker
    size="large"
    placeholder={['Выбрать', '']}
    className={styles.selector}
    onChange={onChange}
    separator=""
    clearIcon={<CloseOutlined />}
    inputReadOnly
    value={value}
    popupClassName={styles.picker}
  />
);
