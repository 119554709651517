import { Drawer } from 'antd';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useWindowDimensions } from '@/hooks';

import { CloseIcon } from '../../assets';
import styles from './styles.module.scss';

interface Props {
  handleClose: () => void;
  mobileDimension?: number;
  open: boolean;
  title: string;
}

export const DrawerWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  handleClose,
  mobileDimension,
  open,
  title,
}) => {
  const windowDimensions = useWindowDimensions();
  const windowWidth = windowDimensions.width;
  const windowHeight = windowDimensions.height;
  const [isMobile, setIsMobile] = useState(windowWidth < mobileDimension);

  useEffect(() => {
    setIsMobile(windowWidth < mobileDimension);
  }, [windowWidth]);

  if (!isMobile) {
    return <>{children}</>;
  }

  return (
    <Drawer
      title={null}
      closable={false}
      placement="bottom"
      onClose={handleClose}
      open={open}
      className={styles.drawer}
      height={windowHeight * 0.8}
      rootClassName={styles.root}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.close} onClick={handleClose}>
          <CloseIcon className={styles.closeIcon} />
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </Drawer>
  );
};
