import {
  EmployeeResponsePrivate,
  GetManyTestsQueryPrivate,
  PermissionAction,
  PermissionResource,
  TestType,
} from '@tests/types';
import { Col, FloatButton, Row, Space, Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import { inc, isEmpty, omit } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ArrowTop, Filters } from '@/assets';
import { TabHeadItem } from '@/atoms';
import { DrawerWrapper, Sort, SortField } from '@/components';
import { DEFAULT_FILTER_KEYS } from '@/constants';
import { TestsList, TestsListHeader, TestsPageFilters } from '@/containers';
import { useFilterPage, usePermissions } from '@/hooks';
import { employeesEntitySelector } from '@/selectors';
import {
  useGetEmployeesListQuery,
  useGetProjectsListQuery,
  useGetTestsCountQuery,
  useLazyGetEmployeeByIdQuery,
} from '@/services';
import { getTestTypeCount, sortFromString, sortToString } from '@/utils';

import styles from './styles.module.scss';

const sortFields: SortField[] = [
  { label: 'По дате создания', value: 'createdAt' },
  { label: 'По дате публикации', value: 'publishedAt' },
];

const hasDefaultFilterKey = (filter: Record<string, any>) =>
  Object.keys(filter).some((key) => DEFAULT_FILTER_KEYS.includes(key));

export const TestsPage: React.FC = () => {
  const { filter, resetFilter, setFilter } = useFilterPage();

  const [showFilter, setShowFilter] = useState<boolean>(hasDefaultFilterKey(filter));
  const [employeesPage, setEmployeesPage] = useState(0);
  const [load, setLoad] = useState(false);

  const [getEmployeeById] = useLazyGetEmployeeByIdQuery();

  useEffect(() => {
    if (!load) {
      setFilter({ offset: 0, page: 1 });
      setLoad(true);
    }
  }, [load]);

  const handleSearch = (value: string) => {
    if (value) {
      setFilter({ offset: 0, page: 1, title: value });
    }
    setFilter({ offset: 0, page: 1, title: value });
  };

  const getTestsCountParams = useMemo(() => {
    let params = {} as Partial<GetManyTestsQueryPrivate>;
    if (!isEmpty(filter)) {
      params = {
        ...params,
        ...omit(['offset', 'page', 'order', 'type'], filter),
      };
    }

    return params;
  }, [filter]);

  const { data: { results: testsCount = [] } = {} } = useGetTestsCountQuery(getTestsCountParams, {
    refetchOnMountOrArgChange: true,
  });

  const { data: employeesData, isFetching: isEmployeesFetching } = useGetEmployeesListQuery(
    {
      offset: employeesPage * 30,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Employee, PermissionAction.Read),
    },
  );

  useGetProjectsListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const employees: EmployeeResponsePrivate<string>[] =
    useSelector(employeesEntitySelector.selectAll) || [];

  useEffect(() => {
    if (employees.length > 0 && filter.createdBy) {
      const isEmployeeLoaded = employees.find(({ id }) => id === +filter.createdBy);
      if (!isEmployeeLoaded) {
        getEmployeeById({ id: filter.createdBy });
      }
    }
  }, [employees]);

  const handleFetchEmployees = async () => {
    setEmployeesPage(inc);
  };

  const handleTabChange = (key: string) => {
    resetFilter({
      ...omit(['type', 'page', 'offset'], filter),
      ...(key !== 'all' && { type: key }),
    });
  };

  const items: TabsProps['items'] = [
    {
      key: 'all',
      label: <TabHeadItem label="Все" testCount={getTestTypeCount(testsCount, 'all')} />,
    },
    {
      key: TestType.Test,
      label: <TabHeadItem label="Тесты" testCount={getTestTypeCount(testsCount, TestType.Test)} />,
    },
    {
      key: TestType.Extended,
      label: (
        <TabHeadItem
          label="Расширенные тесты"
          testCount={getTestTypeCount(testsCount, TestType.Extended)}
        />
      ),
    },
    {
      key: TestType.Quiz,
      label: <TabHeadItem label="Квизы" testCount={getTestTypeCount(testsCount, TestType.Quiz)} />,
    },
    {
      key: TestType.Poll,
      label: (
        <TabHeadItem label="Голосования" testCount={getTestTypeCount(testsCount, TestType.Poll)} />
      ),
    },
    {
      key: TestType.Crossword,
      label: (
        <TabHeadItem
          label="Кроссворды"
          testCount={getTestTypeCount(testsCount, TestType.Crossword)}
        />
      ),
    },
  ];

  const additionalTabButtons = (
    <Space
      className={classNames(styles.showFilterBtn, showFilter && styles.activeShowFilterBtn)}
      onClick={() => setShowFilter(!showFilter)}
    >
      <Row align="middle">
        <Filters />
      </Row>
      <Row align="middle">
        {showFilter ? <Col span={24}>Скрыть фильтры</Col> : <Col span={24}>Все фильтры</Col>}
      </Row>
    </Space>
  );

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <>
      <DefaultTabBar {...props} className={styles.tabBar} />
      <div className={styles.mobileFilterButton}>{additionalTabButtons}</div>
    </>
  );

  return (
    <div className={styles.content}>
      <TestsListHeader onSearch={handleSearch} term={filter.title} />
      <div className={styles.tabs}>
        <Tabs
          tabBarExtraContent={additionalTabButtons}
          tabBarGutter={20}
          defaultActiveKey={filter.type}
          onTabClick={handleTabChange}
          renderTabBar={renderTabBar}
          items={items}
        />
        {showFilter && (
          <DrawerWrapper
            title="Все фильтры"
            open={showFilter}
            handleClose={() => setShowFilter(false)}
            mobileDimension={768}
          >
            <TestsPageFilters
              employeesList={employees.map(({ id, name }) => ({ label: name, value: id }))}
              hasMoreEmployees={employeesData?.count > employees?.length}
              onFetchEmployees={handleFetchEmployees}
              loadingEmployees={isEmployeesFetching}
            />
          </DrawerWrapper>
        )}
        <div className={styles.sort}>
          <Sort
            fields={sortFields}
            value={sortFromString(filter.order)}
            onChange={(value) => setFilter({ offset: 0, order: sortToString(value), page: 1 })}
          />
        </div>
        <TestsList />
      </div>

      <FloatButton.BackTop className={styles.scrollButton} icon={<ArrowTop />} />
    </div>
  );
};
