import { Button, Form } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { anyPass, isEmpty, isNil, omit, reject } from 'ramda';
import { FC, useCallback } from 'react';

import { DateFilter, FormItem, ProjectsFilter, Select } from '@/atoms';
import { DEFAULT_FILTER_KEYS } from '@/constants';
import { useFilterPage } from '@/hooks';
import { useGetTestsListQuery } from '@/services';
import { FiltersItem } from '@/types';
import { statusOptions } from '@/utils';

import styles from './styles.module.scss';

interface Props {
  employeesList: FiltersItem[];
  hasMoreEmployees: boolean;
  loadingEmployees: boolean;
  onFetchEmployees: () => Promise<void>;
}

const isEmptyObject = (obj: Record<string, any>) => {
  if (obj) {
    return Object.values(obj).every((item) => {
      if (Array.isArray(item)) {
        return item.length === 0;
      }

      return isNil(item);
    });
  }

  return true;
};

const clearObject = reject(anyPass([isEmpty, isNil]));

const convertProjects = (projects: string | string[]) => {
  if (projects) {
    if (Array.isArray(projects)) {
      return projects.map((id: string) => ({ value: +id }));
    }

    return [{ value: +projects }];
  }

  return [];
};

const convertDates = (dates: string | string[]) => {
  if (dates) {
    if (Array.isArray(dates)) {
      return dates.map((date: string) => dayjs(date));
    }
  }

  return [];
};

export const TestsPageFilters: FC<Props> = ({
  employeesList,
  hasMoreEmployees,
  loadingEmployees,
  onFetchEmployees,
}) => {
  const { filter, resetFilter, setFilter } = useFilterPage();

  const [form] = useForm<{
    createdAt: Dayjs[];
    createdBy: number;
    projects: FiltersItem[];
    publishedAt: Dayjs[];
    status: string;
  }>();

  const RESET_FILTER_KEYS = ['offset', 'page', ...DEFAULT_FILTER_KEYS];
  const data = useWatch([], form);

  const onFinish = useCallback(() => {
    resetFilter({
      ...omit(RESET_FILTER_KEYS, filter),
      ...omit(['projects'], clearObject(data)),
      ...(data.projects.length > 0 ? { projects: data.projects.map((item) => item.value) } : {}),
      ...(data?.createdAt?.length > 0
        ? { createdAt: data.createdAt.map((item) => item.toISOString()) }
        : {}),
      ...(data?.publishedAt?.length > 0
        ? { publishedAt: data.publishedAt.map((item) => item.toISOString()) }
        : {}),
    });
  }, [data, setFilter]);

  const handleReset = () => {
    resetFilter(omit(RESET_FILTER_KEYS, filter));
  };

  const { isFetching } = useGetTestsListQuery(
    {
      ...omit(['page'], filter),
    },
    {
      skip: true,
    },
  );

  const initialValues = {
    createdAt: convertDates(filter?.createdAt),
    createdBy: filter?.createdBy ? +filter.createdBy : null,
    projects: convertProjects(filter?.projects),
    publishedAt: convertDates(filter?.publishedAt),
    status: filter?.status,
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className={styles.filtersForm}
      initialValues={initialValues}
    >
      <div className={styles.container}>
        <FormItem name="projects" label="Проект" flex>
          <ProjectsFilter />
        </FormItem>
        <FormItem name="createdBy" label="Сотрудник" flex>
          <Select
            options={employeesList}
            hasMore={hasMoreEmployees}
            fetchData={onFetchEmployees}
            loading={loadingEmployees}
          />
        </FormItem>
        <FormItem name="status" label="Статус" flex>
          <Select options={statusOptions} />
        </FormItem>
        <FormItem name="createdAt" label="Период создания" flex>
          <DateFilter />
        </FormItem>
        <FormItem name="publishedAt" label="Период публикации" flex>
          <DateFilter />
        </FormItem>
      </div>
      <div className={styles.filtersBtnWrapper}>
        <Button
          className={classNames(styles.filtersBtn, styles.resetFilters)}
          disabled={isEmptyObject(data)}
          onClick={handleReset}
          htmlType="reset"
        >
          Сбросить
        </Button>

        <Button
          htmlType="submit"
          disabled={isFetching || isEmptyObject(data)}
          className={classNames(styles.filtersBtn, styles.filtersSubmitBtn)}
          type="primary"
        >
          Применить
        </Button>
      </div>
    </Form>
  );
};
