import { TestResponsePrivate } from '@tests/types';
import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { Attachments, Link as LinkIcon, Stats, TestoLogo } from '@/assets';
import { message } from '@/atoms';
import { Badge, DateChanged, Status } from '@/components';
import { RoutesPath } from '@/constants';
import { getTypeTitle } from '@/utils';

import styles from './styles.module.scss';

interface Props {
  test: TestResponsePrivate;
}

export const TestCardNew: React.FC<Props> = (props) => {
  const {
    test: { title, type, questionsCount, createdAt, updatedAt, projects = [], status, id },
  } = props;

  const handleCopyTestLinkClick = () => {
    navigator.clipboard
      .writeText(
        generatePath(`${window.location.origin}${RoutesPath.TestEdit}`, {
          idOrAlias: id.toString(10),
        }),
      )
      .then(() =>
        message({
          description: 'Ссылка на интерактив скопирована',
          type: 'success',
        }),
      );
  };

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div className={styles.topInfo}>
          <Badge className={type}>{getTypeTitle(type)}</Badge>
          <Status status={status} />
        </div>
        <div className={styles.topActions}>
          <Attachments />
          {questionsCount}
        </div>
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.dates}>
        <DateChanged date={createdAt} type="created" />
        <DateChanged date={updatedAt} type="updated" />
      </div>
      <div className={styles.divider} />
      <div className={styles.projectsWrapper}>
        <div className={styles.projects}>
          {projects.map((project) => (
            <Badge className="project" key={project.id}>
              {project?.logo?.url ? (
                <img height={12} src={project?.logo?.url} alt={project.alias} />
              ) : (
                <TestoLogo style={{ height: '12px' }} />
              )}
            </Badge>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <Link to={`/test/${id}`} className={styles.buttonLink}>
          <Button className={styles.button}>Редактировать</Button>
        </Link>
        <Link
          to={`/test/stats/${id}`}
          className={classNames(styles.buttonLink, styles.buttonSmall)}
        >
          <Button className={classNames(styles.button, styles.buttonSmall)}>
            <Stats />
          </Button>
        </Link>
        <Button
          className={classNames(styles.button, styles.buttonSmall)}
          onClick={handleCopyTestLinkClick}
        >
          <LinkIcon />
        </Button>
      </div>
    </div>
  );
};
