import { LeftOutlined } from '@ant-design/icons';
import { TestResponsePrivate, TestType } from '@tests/types';
import { Button, Col, message, Modal, Row } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseIcon, Delete, Menu } from '@/assets';
import { Badge, DateChanged, Status, Visible } from '@/components';
import { RoutesPath } from '@/constants';
import { useDisabledByUpdate, useFilterPage } from '@/hooks';
import {
  useDeleteTestMutation,
  usePublishTestMutation,
  useUnpublishTestMutation,
} from '@/services';
import { isDraft, isPublished, isUnpublished } from '@/utils';

import styles from './styles.module.scss';

const { confirm } = Modal;

type Props = {
  isMenuShowed?: boolean;
  onMenuClick?: () => void;
  test: TestResponsePrivate;
};

const getTypeTitle = cond<[string], Nullable<string>>([
  [equals(TestType.Test), always('Тест')],
  [equals(TestType.Quiz), always('Квиз')],
  [equals(TestType.Extended), always('Расширенный тест')],
  [equals(TestType.Poll), always('Голосование')],
  [equals(TestType.Crossword), always('Кроссворд')],
  [T, always(null)],
]);

export const TestEditPageHeader: React.FC<Props> = ({ isMenuShowed, onMenuClick, test }) => {
  const { createdAt, hasUnpublishedChanges, id, status, type, updatedAt } = test;
  const navigate = useNavigate();

  const [deleteTest, { isLoading: isDeleting }] = useDeleteTestMutation();

  const [publishTest] = usePublishTestMutation();
  const [unpublishTest] = useUnpublishTestMutation();

  const handleDeleteClick = useCallback(async () => {
    confirm({
      onOk: async () => {
        await deleteTest(id);
        navigate(RoutesPath.Tests);
        message.success('Тест удален');
      },
      title: 'Вы действительно хотите удалить тест?',
    });
  }, [deleteTest, id]);

  const handlePublishTestClick = useCallback(() => {
    publishTest({ id });
  }, [id]);

  const handleUnpublishTestClick = useCallback(() => {
    unpublishTest({ id });
  }, [id]);

  const handleGoBackButton = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      navigate('/tests');
    }
  };

  const disabled = useDisabledByUpdate();

  return (
    <div className={styles.header}>
      <div className={styles.headerMobile}>
        <Col className={styles.goBackButton} onClick={handleGoBackButton}>
          <LeftOutlined /> Назад
        </Col>
        <Col className={styles.menuButton} onClick={onMenuClick}>
          {isMenuShowed ? <CloseIcon /> : <Menu />}
        </Col>
      </div>
      <Row justify="space-between" align="middle" className={styles.headerRow}>
        <div className={styles.headerCol}>
          <Row gutter={[16, 16]} align="middle" className={styles.headerTop}>
            <Col className={styles.goBackButton} onClick={handleGoBackButton}>
              <LeftOutlined /> Назад
            </Col>
            <Col>
              <Row align="middle" gutter={[7, 7]}>
                <Col>
                  <Badge className={type}>{getTypeTitle(type)}</Badge>
                </Col>
                <Col>
                  <Status status={status} />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <div className={styles.datesRow}>
                <div className={styles.dates}>
                  <DateChanged date={createdAt} type="created" nowrap />
                </div>
                <div className={styles.separator} />
                <div className={styles.dates}>
                  <DateChanged date={updatedAt} type="updated" nowrap />
                </div>
                <div className={styles.separator} />
                <div className={styles.dates}>
                  <DateChanged date={updatedAt} type="saved" nowrap />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={classNames(styles.headerCol, styles.headerColButtons)}>
          <div className={styles.mainButtons}>
            <Button
              className={classNames(styles.defaultButton, styles.deleteButton)}
              onClick={handleDeleteClick}
            >
              <Delete />
            </Button>
            <Button
              className={classNames(styles.defaultButton, !isPublished(status) && styles.disabled)}
              disabled={!isPublished(status)}
              loading={isDeleting}
              onClick={handleUnpublishTestClick}
            >
              Снять с публикации
            </Button>
          </div>
          <Visible isVisible={isUnpublished(status) || isDraft(status)}>
            <Button
              className={classNames(styles.primaryButton, disabled && styles.disabled)}
              disabled={disabled}
              onClick={handlePublishTestClick}
            >
              Опубликовать
            </Button>
          </Visible>
          <Visible isVisible={isPublished(status)}>
            <Button
              className={classNames(
                styles.primaryButton,
                (disabled || !hasUnpublishedChanges) && styles.disabled,
              )}
              disabled={disabled || !hasUnpublishedChanges}
              onClick={handlePublishTestClick}
            >
              Применить
            </Button>
          </Visible>
        </div>
      </Row>
    </div>
  );
};
